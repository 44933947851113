<!-- @format -->

<template>
  <v-card>
    <v-card-text>
      <v-row>
        <v-col>
          <v-text-field
            flat
            solo-inverted
            hide-details
            prepend-inner-icon="mdi-magnify"
            label="Search"
            v-model="search"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-btn-toggle v-model="votoSelect" mandatory>
            <v-btn value="T"> Todos </v-btn>
            <v-btn value="S"> Aprova </v-btn>
            <v-btn value="N"> Não aprova </v-btn>
            <v-btn value="A"> Abstenção </v-btn>
            <v-btn value="I"> Indefinido </v-btn>
            <v-btn value="presentes"> Presentes </v-btn>
            <v-btn value="ausentes"> Ausentes </v-btn>
          </v-btn-toggle>
        </v-col>
      </v-row>
      <v-data-table
        :headers="headers"
        :items="credorSort"
        multi-sort
        class="elevation-1"
        height="100%"
        dense
        fixed-header
      >
        <template v-slot:[`item.voto`]="{ item }">
          <div
            class="text-truncate"
            style="max-width: 300px"
            :title="item.voto"
          >
            <voto-icon :voto="item.voto" />
          </div>
        </template>

        <template v-slot:[`item.nome`]="{ item }">
          <div
            class="text-truncate"
            style="max-width: 300px"
            :title="item.nome"
          >
            {{ item.nome }}
          </div>
        </template>
        <template v-slot:[`item.presente`]="{ item }">
          <div
            class="text-truncate"
            style="max-width: 300px"
            :title="item.presente"
          >
            {{ descricaoPresente(item.presente) }}
          </div>
        </template>

        <template v-slot:[`item.total`]="{ item }">
          <span>{{ $n(item.total, "currency") }}</span>
        </template>
        <template v-slot:[`item.data`]="{ item }">
          {{ item.data | moment("DD/MM/YYYY HH:mm:ss") }}
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import _ from "lodash";
import Format from "@/services/format";
import VotoIcon from "@/components/assembleia/VotoIcon.vue";
import router from "@/router";
import { broadcast } from "@/plugins/pusher";
import { assembleiaStore } from "@/store/assembleia/";

export default {
  components: { VotoIcon },
  data: () => ({
    votoSelect: "T",
    search: "",
    headers: [
      {
        text: "NOME",
        align: "start",
        value: "nome",
      },
      {
        text: "VALOR",
        align: "end",
        value: "total",
      },
      {
        text: "CLASSE",
        align: "start",
        value: "classe",
      },
      {
        text: "VOTO",
        align: "start",
        sortable: true,
        value: "voto",
      },
      {
        text: "PROCURADOR",
        align: "start",
        sortable: false,
        value: "observacao",
      },
    ],
  }),
  computed: {
    credorSort() {
      return _.sortBy(this.credorfilter, ["classe", "nome"]);
    },

    credorfilter() {
      let credores = [];
      let presentes =
        this.votoSelect === "presentes"
          ? true
          : this.votoSelect === "ausentes"
          ? false
          : null;
      //todos
      credores = this.credores;
      if (presentes !== null) {
        credores = credores.filter((credor) => credor.presente == presentes);
      }

      if (this.votoSelect !== "T" && presentes === null) {
        credores = credores.filter((credor) => credor.voto == this.votoSelect);
      }

      if (this.search === "") {
        return credores;
      }
      return (
        credores.filter(
          (credor) =>
            Format.search(credor.nome, this.search) ||
            Format.search(credor.email, this.search) ||
            Format.search(
              Format.documentoLimpo(credor.documento),
              Format.documentoLimpo(this.search)
            )
        ) || []
      );
    },
    ultimosPresentes() {
      return this.credores
        .filter((credor) => credor.presente)
        .sort((one, two) => (one.data > two.data ? -1 : 1))
        .slice(0, 10);
    },
    credores() {
      return assembleiaStore.credores;
    },
  },
  methods: {
    descricaoPresente(presente) {
      return presente ? "Sim" : "Não";
    },
  },
  async mounted() {
    this.loading = true;
    if (!assembleiaStore.credores) {
      const assembleiaId = Number(router.currentRoute.params.id);
      await assembleiaStore.getAssembleia(assembleiaId);
      await assembleiaStore.getCredores(assembleiaId);
    }
    broadcast.subscribeAssembleia(10 * 1000);
    this.loading = false;
  },
};
</script>

<style scoped>
span.small {
  font-size: 8;
  line-height: 9;
}
</style>
