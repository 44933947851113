import { render, staticRenderFns } from "./AssembleiaResumoPainel.vue?vue&type=template&id=6889b4a3"
import script from "./AssembleiaResumoPainel.vue?vue&type=script&lang=js"
export * from "./AssembleiaResumoPainel.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/ubuntu/projetos/admjudicial-api-l8/releases/422/frontend/plano-recuperacao-ui/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6889b4a3')) {
      api.createRecord('6889b4a3', component.options)
    } else {
      api.reload('6889b4a3', component.options)
    }
    module.hot.accept("./AssembleiaResumoPainel.vue?vue&type=template&id=6889b4a3", function () {
      api.rerender('6889b4a3', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/assembleia/AssembleiaResumoPainel.vue"
export default component.exports