import { render, staticRenderFns } from "./ActionObservacao.vue?vue&type=template&id=7d204bd4"
import script from "./ActionObservacao.vue?vue&type=script&lang=ts"
export * from "./ActionObservacao.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/ubuntu/projetos/admjudicial-api-l8/releases/422/frontend/plano-recuperacao-ui/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7d204bd4')) {
      api.createRecord('7d204bd4', component.options)
    } else {
      api.reload('7d204bd4', component.options)
    }
    module.hot.accept("./ActionObservacao.vue?vue&type=template&id=7d204bd4", function () {
      api.rerender('7d204bd4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/assembleia/ActionObservacao.vue"
export default component.exports