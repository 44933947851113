var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "assembleia-grafico border rounded-sm" },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _vm.showTable
                ? _c("assembleia-classe", {
                    attrs: { resultado: _vm.resultado, titulo: _vm.titulo },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c("grafico-votacao", {
                attrs: { titulo: _vm.titulo, resultado: _vm.resultado },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }