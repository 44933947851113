import { render, staticRenderFns } from "./PlanoGestao.vue?vue&type=template&id=730a1c69&scoped=true"
import script from "./PlanoGestao.vue?vue&type=script&lang=ts"
export * from "./PlanoGestao.vue?vue&type=script&lang=ts"
import style0 from "./PlanoGestao.vue?vue&type=style&index=0&id=730a1c69&lang=css&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "730a1c69",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/ubuntu/projetos/admjudicial-api-l8/releases/422/frontend/plano-recuperacao-ui/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('730a1c69')) {
      api.createRecord('730a1c69', component.options)
    } else {
      api.reload('730a1c69', component.options)
    }
    module.hot.accept("./PlanoGestao.vue?vue&type=template&id=730a1c69&scoped=true", function () {
      api.rerender('730a1c69', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/PlanoGestao.vue"
export default component.exports