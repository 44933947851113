var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    [
      _vm.titulo != "Total"
        ? _c("v-col", { attrs: { lg: "6" } }, [
            _c(
              "div",
              { staticClass: "small" },
              [
                _vm.datacollectionCredores
                  ? _c("bar-chart", {
                      attrs: {
                        "chart-data": _vm.datacollectionCredores,
                        options: _vm.optionsCredores,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm.mostraCredito
        ? _c("v-col", { attrs: { lg: "6" } }, [
            _c(
              "div",
              { staticClass: "small" },
              [
                _vm.datacollectionCreditos
                  ? _c("bar-chart", {
                      attrs: {
                        "chart-data": _vm.datacollectionCreditos,
                        options: _vm.optionsCreditos,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }